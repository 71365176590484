import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { APP_VERSION } from '../../config';
import { SETTINGS_KEYS } from './d';
import { CONTEXT_TYPES } from '../../context/d';
import { isArray } from 'lodash';

// Define a type for the slice state
interface AppState {
    version: string
    settings?: any,
    drivers?: any,
    vehicles?: any,
    user?: any,
    token?: string
    refreshToken?: string
    open: boolean
    shiftDrivers?: any
    openMapInWindow?: boolean,
    mapState?: any,
    orderFinished?: any
    shiftResume?: any
    shiftPause?: any
    isOrderStarted?: boolean
}

// Define the initial state using that type
const initialState: AppState = {
    version: APP_VERSION || '',
    open: false,
    token: localStorage.getItem(CONTEXT_TYPES.ACCESS_TOKEN) as string || undefined,
    refreshToken: localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN) as string || undefined,
    orderFinished: [],
    shiftPause: [],
    shiftResume: [],
    openMapInWindow: !!localStorage.getItem('mapOpen'),
    isOrderStarted: !!localStorage.getItem('ORDER_FORMIK')
};

export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setVersion: (state, action: PayloadAction<string>) => {
            state.version = action.payload;
        },
        setSettings: (state, action: PayloadAction<any>) => {
            state.settings = {
                ...(state?.settings || {}),
                ...action.payload
            };
        },
        setDrivers: (state, action: PayloadAction<any>) => {
            state.drivers = [...(action.payload || [])];
        },
        setVehicles: (state, action: PayloadAction<any>) => {
            state.vehicles = [...(action.payload || [])];
        },
        setAuthData: (state, action: PayloadAction<any>) => {
            const {account, token, refreshToken} = action.payload;
            state.user = {...account};
            state.token = token;
            state.refreshToken = refreshToken;
            if (token) {
                localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, token);
            }
            if (refreshToken) {
                localStorage.setItem(CONTEXT_TYPES.REFRESH_TOKEN, refreshToken);
            }
        },
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload;
            if (action.payload) {
                localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, action.payload);
            } else {
                localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            }
        },
        setRefreshToken: (state, action: PayloadAction<any>) => {
            state.refreshToken = action.payload;
            if (action.payload) {
                localStorage.setItem(CONTEXT_TYPES.REFRESH_TOKEN, action.payload);
            } else {
                localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
            }
        },
        clearUserData: (state) => {
            state.refreshToken = undefined;
            state.token = undefined;
            state.user = undefined;
            localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
        },
        setShiftDrivers:  (state, action: PayloadAction<any>) => {
            state.shiftDrivers = [...action.payload];
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setOpenMap: (state, action: PayloadAction<boolean>) => {
            state.openMapInWindow = action.payload;
        },
        setOrderStarted: (state, action: PayloadAction<boolean>) => {
            state.isOrderStarted = action.payload;
        },
        setMapState: (state, action: PayloadAction<any>) => {
            state.mapState = {
                ...state.mapState,
                ...action.payload
            };
        },
        setMapStateByDriver:(state, action: PayloadAction<{driverId: number, data: any}>) => {
           const driverData = (state?.shiftDrivers || []).find((x: any)=> x.driverId === action.payload.driverId);
           if(driverData) {
               state.mapState = {
                   ...state.mapState,
                   [action.payload.driverId] : {
                       lat: action.payload.data?.lat,
                       lng: action.payload.data?.lng,
                       driverId: driverData.driverId,
                       vehicleType: driverData?.vehicle?.vehicleType,
                       driver: driverData ? `${driverData?.driver.firstName} ${driverData?.driver?.lastName}` : '',
                       vehicleId: driverData?.vehicleId,
                       vehicleNumber: driverData?.vehicle?.vehicleNumber,
                       status: driverData?.status,
                       isBusy: driverData?.currentOrders ? 1 : 0,
                       isFirstCompleted: driverData?.isFirstCompleted,
                       driverOnDuty: driverData?.driverOnDuty,
                       lastTimeActive: new Date().getTime()
                   }
               };
           }
        },
        setOrderFinished: (state,action: PayloadAction<number|number[]>) => {
            state.orderFinished = [...state.orderFinished, ...(isArray(action.payload) ? [...action.payload] : [action.payload])];
        },
        clearOrderFinished: (state) => {
            state.orderFinished = [];
        },
        setShiftPause: (state,action: PayloadAction<number|number[]>) => {
            state.shiftPause = [...state.shiftPause, ...(isArray(action.payload) ? [...action.payload] : [action.payload])];
        },
        setShiftResume: (state,action: PayloadAction<number|number[]>) => {
            state.shiftResume = [...state.shiftResume, ...(isArray(action.payload) ? [...action.payload] : [action.payload])];
        },
        clearShiftStatusChanged: (state, action: PayloadAction<'shiftPause'|'shiftResume'>) => {
            (state as any)[action.payload] = [];
        }
    }
});

export const { setVersion, setSettings, setVehicles, setDrivers, setOpen, setUser, setShiftDrivers, setToken, setRefreshToken, setAuthData, clearUserData, setOpenMap, setMapState, setMapStateByDriver, setOrderFinished, clearOrderFinished, setShiftPause, setShiftResume, clearShiftStatusChanged, setOrderStarted } = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVersion = (state: RootState) => state.app.version;
export const selectDrivers = (state: RootState) => state.app.drivers || [];
export const selectVehicles = (state: RootState) => state.app?.vehicles || [];
export const selectSettings = (state: RootState) => state.app.settings || {};
export const selectSettingsBiddingTime = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.BIDDING_TIME]?.value || '';
export const selectSettingsBiddingAutoStartTime = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.BIDDING_AUTO_START_TIME]?.value || '';
export const selectSettingsScheduledPendingTime = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_SCHEDULED_PENDING_TIME]?.value || '';
export const selectSettingsVehicleStartAmount = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_START_AMOUNT]?.value || '5000.00';

export const selectSettingsOrderZoneOne  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_ZONE_1]?.valueJson;
export const selectSettingsOrderZoneTwo  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_ZONE_2]?.valueJson;
export const selectSettingsOrderZoneThree  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_ZONE_3]?.valueJson;
export const selectSettingsOrderStopPriceNoAdd  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_STOP_PRICE_NO_ADDRESS]?.value;
export const selectSettingsOrderPurchaseStopPriceNoAdd  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_PURCHASE_STOP_PRICE_NO_ADDRESS]?.valueJson || {};
export const selectSettingsOrderDistanceStartCalculation  = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.ORDER_DISTANCE_START_CALCULATION]?.value;


export const selectSettingsShifts = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_SCHEDULING]?.valueJson || {};
export const selectGetVehicle = (id: number) => (state: RootState) => (state.app?.vehicles || []).find((x: any)=> x.id === id) || {};
export const selectGetDriver = (id: number) => (state: RootState) => (state.app?.drivers || []).find((x: any)=> x.id === id) || {};
export const selectAppStore = (state: RootState) => state.app;
export const selectToken = (state: RootState) => state.app.token;
export const selectUser = (state: RootState) => state.app.user;
export const selectIsAuthorized = (state: RootState) => !!state.app.token;
export const selectShiftDrivers = (state: RootState) => state.app.shiftDrivers;
export const selectMapState = (state: RootState) => state.app.mapState;
export const selectIsMapOpen = (state: RootState) => state.app.openMapInWindow;
export const selectIsOrderStarted = (state: RootState) => state.app.isOrderStarted;
export const selectOrderFinished = (id: number) => (state: RootState) => !!state.app.orderFinished.find((x: any) => x === id);
export const selectShiftDriverPause = (id: number) => (state: RootState) => !!state.app.shiftPause.find((x: any) => x === id);
export const selectShiftPause = (state: RootState) => state.app.shiftPause;
export const selectShiftResume = (state: RootState) => state.app.shiftResume;
export default appSlice.reducer;