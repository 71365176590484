import React, { useCallback, useContext, useMemo } from 'react';
import {
    OrderDeliveryAmountContainer,
    OrderDeliveryAmountRow,
    OrderItemCheckedIcon,
    OrderItemColumn,
    OrderItemContainer,
    OrderItemContent,
    OrderItemIcons,
    OrderItemLeftActions,
    OrderItemLeftCheckArea,
    OrderItemRightActionButton,
    OrderItemRightActions,
    OrderItemRow,
    OrderItemStopsCount
} from './style';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeOfTravelOutlinedIcon from '@mui/icons-material/ModeOfTravelOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneIcon from '@mui/icons-material/Done';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SearchIcon from '@mui/icons-material/Search';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import GavelIcon from '@mui/icons-material/Gavel';
import { useTranslation } from 'react-i18next';
import { ORDER_BIDDING_STATUS, ORDER_STATUS } from '../../../../d';
import { Translate } from 'internationalization/translate';
import { OrderViewActionsListEvents } from '../d';
import PickUpTimeView from './PickUpTimeView';
import OrderViewAssignContext from '../../context/context';
import { formatCurrency } from '../../../../../../utils';
import ConditionalRendering from '../../../../../../components/Utils/ConditionalRendering';
import moment from 'moment-timezone';
import { selectOrderFinished } from '../../../../../../store/app/appSlice';
import { useSelector } from 'react-redux';

const OrderItem = ({ order }: any) => {
    const { setOrder, selectedOrders, selectedDriver } = useContext(OrderViewAssignContext);
    const orderFinished = useSelector(selectOrderFinished(order?.id));
    const { t } = useTranslation();
    const [color, item, vehicleTypeOrderNum, orderDeliveryAmount, selected, isActionsAble, isAborted, isScheduled, orderTime, isCustomerNotShow, isDriverSeen, itemsCount, isOrderFinished, isPending, isBidding, hideBiddingIcon, isCompleted] = useMemo(() => [
        (() => {
            if (order.status === ORDER_STATUS.COMPLETED) return 'black';
            if (order.isCustomerNotShow) return 'orange';
            if (order.status === ORDER_STATUS.SCHEDULED) return 'grey';
            if (![ORDER_STATUS.REJECTED].includes(order.status) && order.items.every((item: {
                isCompleted: number;
            }) => item.isCompleted === 1)) return 'red';
            if (![ORDER_STATUS.REJECTED].includes(order.status) && order.items.some((item: {
                isCompleted: number;
            }) => item.isCompleted === 1)) return 'purple';
            if ([ORDER_STATUS.PENDING, ORDER_STATUS.PENDING_SA].includes(order.status) || !order?.driverId) return 'blue';
            if ([ORDER_STATUS.ACCEPTED, ORDER_STATUS.ASSIGNED, ORDER_STATUS.READY, ORDER_STATUS.PICK_UP, ORDER_STATUS.PENDING_EDIT_PRICE].includes(order.status) && order.driverId) return 'green';
            return 'red';
        })(),
        (() => {
            const item = order?.items?.[0];
            const arr = item?.address?.split(',');
            arr && arr.pop();
            const locationAddress = arr?.join(',') || '';
            const pickUpTime = item?.pickUpTime;
            const customerAddress = order?.customerAddress || '';
            return {
                itemAddress: locationAddress,
                customerData: `${order.customerName} (${customerAddress}${order.customerBuildingNumber ? ` - ${order.customerBuildingNumber}` : ''})`,
                pickUpTime,
                isCompleted: item?.isCompleted,
                completedTime: item?.completedTime
            };
        })(),
        (() => {
            if (!order?.driver) return `#${order.dailyNumber}`;
            const driver = order?.driver;
            const driverText = `${driver?.firstName} ${driver?.lastName}`;
            return `br. vožnje ${order.dailyNumber} / ${driverText}`;
        })(),
        (() => {
            if (!order?.orderAmount && !order?.orderDeliveryAmount) return '';
            const isStopPurchaseNotCompleted = !!(order?.items?.filter((x: any) => x.isPurchase && (!x?.amount || Number(x?.amount) <= 0))?.length);
            return (<OrderDeliveryAmountRow>
                <div>{`Narudžbina: ${order?.orderAmount || '##.##'} /`}</div>
                <OrderDeliveryAmountContainer
                    isPurchase={isStopPurchaseNotCompleted}>{`Dostava: ${formatCurrency((order.orderDeliveryAmount || '')?.replace(/\./gmi, ',')) || ''}`}</OrderDeliveryAmountContainer>
            </OrderDeliveryAmountRow>);
        })(),
        !!(selectedOrders && selectedOrders?.find((x: any) => `${x}` === `${order?.id}`)),
        [ORDER_STATUS.COMPLETED, ORDER_STATUS.REJECTED, ORDER_STATUS.ARCHIVED].includes(order.status),
        !!order.isAborted,
        order.status === ORDER_STATUS.SCHEDULED,
        (() => {
            if (!order?.orderTime) return '';
            const time = moment(`${order?.orderDate} ${order?.orderTime}`).tz('Europe/Belgrade').format('DD.MM.YYYY HH:mm');
            return `Vreme voznje ${time}`;
        })(),
        !!order.isCustomerNotShow,
        !!order.isDriverSeen,
        (order?.items || []).length - 1,
        orderFinished,
        [ORDER_STATUS.PENDING, ORDER_STATUS.PENDING_SA, ORDER_STATUS.PENDING_EDIT_PRICE].includes(order.status),
        order.isBidding,
        order?.bidding && order?.bidding?.status !== ORDER_BIDDING_STATUS.START,
        order?.status === ORDER_STATUS.COMPLETED
    ], [order, t, selectedOrders, orderFinished]);

    const addRemoveOrder = useCallback(() => {
        if (!order?.id || order?.driverId || !selectedDriver || order.isAborted || order.isCustomerNotShow) return;
        setOrder(order?.id);
    }, [setOrder, order, selectedDriver]);

    return (
        <OrderItemContainer color={color} selected={+selected} allowed={selectedDriver && Number(!!order?.driverId)}
                            isCompleted={isCompleted} isCustomerNotShow={isCustomerNotShow}
                            isAborted={+isAborted} pulse={+isOrderFinished || +isBidding}>
            <OrderItemLeftActions>
                <OrderItemIcons>
                    <OrderItemLeftCheckArea
                        allowed={selectedDriver && Number(!!order?.driverId)}
                        title={t(Translate.orderViewsListActionSelectOrder)}
                        onClick={addRemoveOrder}
                        disabled={isAborted || isScheduled}
                    >
                        {selected ? <OrderItemCheckedIcon /> : <></>}
                    </OrderItemLeftCheckArea>
                </OrderItemIcons>
                <ConditionalRendering condition={!isDriverSeen}>
                    <OrderItemIcons align={'center'} opacity={1} title={t(Translate.orderViewsListDispatcherCreated)}>
                        <DoneIcon />
                    </OrderItemIcons>
                </ConditionalRendering>
                <ConditionalRendering condition={!!isDriverSeen}>
                    <OrderItemIcons align={'center'} opacity={1} title={t(Translate.orderViewsListDriverSaw)}>
                        <DoneAllOutlinedIcon />
                    </OrderItemIcons>
                </ConditionalRendering>
                <OrderItemIcons align={'flex-end'} opacity={1} title={t(Translate.orderViewsListDispatcherCreated)}>
                    <ContactPhoneIcon />
                </OrderItemIcons>
            </OrderItemLeftActions>
            <OrderItemContent>
                <OrderItemRow justify={'flex-start'} paddingRight={itemsCount ? 25 : 0}>
                    <OrderItemColumn>
                        <PickUpTimeView {...item} isCustomerNotShow={order.isCustomerNotShow} />
                    </OrderItemColumn>
                    <div>-</div>
                    <OrderItemColumn>
                        {item?.customerData}
                    </OrderItemColumn>
                    <ConditionalRendering condition={!!itemsCount}>
                        <OrderItemStopsCount>
                            {`+${itemsCount}`}
                        </OrderItemStopsCount>
                    </ConditionalRendering>
                </OrderItemRow>
                <OrderItemRow>
                    <div>{vehicleTypeOrderNum}</div>
                    {orderDeliveryAmount}
                </OrderItemRow>
                <ConditionalRendering condition={!!isScheduled || !!orderTime}>
                    <OrderItemRow>
                        <div>{orderTime}</div>
                    </OrderItemRow>
                </ConditionalRendering>
            </OrderItemContent>
            <OrderItemRightActions>
                <OrderItemIcons align={'center'}>
                    <OrderItemRow>
                        <ConditionalRendering condition={!isActionsAble}>
                            <OrderItemRightActionButton
                                disabled={isAborted || isBidding || isScheduled}
                                title={t(Translate.orderViewsListActionEditInfo)}
                                data-action={OrderViewActionsListEvents.EDIT_ORDER_INFO}
                                data-action-id={order.id}
                                data-action-param={JSON.stringify(order)}
                            >
                                <ManageAccountsOutlinedIcon />
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                        <OrderItemRightActionButton
                            disabled={isBidding || isScheduled}
                            title={t(Translate.orderViewsListActionPreview)}
                            data-action={OrderViewActionsListEvents.PREVIEW_ORDER}
                            data-action-id={order.id}
                            data-action-param={JSON.stringify(order)}
                        >
                            <SearchIcon />
                        </OrderItemRightActionButton>
                        <ConditionalRendering condition={!isActionsAble}>
                            <OrderItemRightActionButton
                                disabled={isAborted || isBidding || isScheduled}
                                title={t(Translate.orderViewsListActionAbort)}
                                data-action={OrderViewActionsListEvents.ABORT_ORDER}
                                data-action-id={order.id}
                                data-action-param={JSON.stringify(order)}
                            >
                                <DangerousIcon />
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                        <ConditionalRendering condition={!isActionsAble && isCustomerNotShow}>
                            <OrderItemRightActionButton
                                disabled={isBidding || isScheduled}
                                title={t(Translate.orderViewsListActionActive)}
                                data-action={OrderViewActionsListEvents.MAKE_ACTIVE}
                                data-action-id={order.id}
                                data-action-param={JSON.stringify(order)}
                            >
                                <AutorenewIcon />
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                        <ConditionalRendering condition={!isActionsAble && !isCustomerNotShow}>
                            <OrderItemRightActionButton
                                inactive={1}
                            >
                                <>&nbsp;</>
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                    </OrderItemRow>
                </OrderItemIcons>
                <OrderItemIcons align={'center'}>
                    <OrderItemRow>
                        <ConditionalRendering condition={!isActionsAble}>
                            <OrderItemRightActionButton
                                disabled={isAborted || isBidding || isScheduled}
                                title={t(Translate.orderViewsListActionAddStop)}
                                data-action={OrderViewActionsListEvents.ADD_STOP}
                                data-action-id={order.id}
                                data-action-param={JSON.stringify(order)}
                            >
                                <ModeOfTravelOutlinedIcon />
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                        <ConditionalRendering condition={!isActionsAble}>
                            <OrderItemRightActionButton
                                disabled={isAborted || isBidding || isScheduled}
                                title={t(Translate.orderViewsListActionEditPrice)}
                                data-action={OrderViewActionsListEvents.CHANGE_EDIT_PRICE}
                                data-action-id={order.id}
                                data-action-param={JSON.stringify(order)}
                            >
                                <MonetizationOnOutlinedIcon />
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                        <ConditionalRendering condition={!isActionsAble}>
                            <ConditionalRendering condition={isScheduled}>
                                <OrderItemRightActionButton
                                    disabled={isAborted || isBidding}
                                    title={t(Translate.orderViewsListActionSetToPending)}
                                    data-action={OrderViewActionsListEvents.SET_TO_PENDING}
                                    data-action-id={order.id}
                                    data-action-param={JSON.stringify(order)}
                                >
                                    <PendingActionsIcon />
                                </OrderItemRightActionButton>
                            </ConditionalRendering>
                            <ConditionalRendering condition={!isScheduled}>
                                <OrderItemRightActionButton
                                    inactive={1}
                                >
                                    <>&nbsp;</>
                                </OrderItemRightActionButton>
                            </ConditionalRendering>
                        </ConditionalRendering>
                        <ConditionalRendering condition={isPending}>
                            <ConditionalRendering condition={!isBidding && !hideBiddingIcon}>
                                <OrderItemRightActionButton
                                    disabled={isAborted || isBidding || isScheduled}
                                    title={t(Translate.orderViewsListActionSetBidding)}
                                    data-action={OrderViewActionsListEvents.SET_TO_BIDDING}
                                    data-action-id={order.id}
                                    data-action-param={JSON.stringify(order)}
                                >
                                    <GavelIcon />
                                </OrderItemRightActionButton>
                            </ConditionalRendering>
                            <ConditionalRendering condition={!isBidding && hideBiddingIcon}>
                                <OrderItemRightActionButton
                                    inactive={1}
                                >
                                    <>&nbsp;</>
                                </OrderItemRightActionButton>
                            </ConditionalRendering>
                            <ConditionalRendering condition={isBidding}>
                                <OrderItemRightActionButton
                                    inactive={1}
                                >
                                    <>&nbsp;</>
                                </OrderItemRightActionButton>
                            </ConditionalRendering>
                        </ConditionalRendering>
                        <ConditionalRendering condition={!isPending}>
                            <OrderItemRightActionButton
                                inactive={1}
                            >
                                <>&nbsp;</>
                            </OrderItemRightActionButton>
                        </ConditionalRendering>
                    </OrderItemRow>
                </OrderItemIcons>
            </OrderItemRightActions>
        </OrderItemContainer>
    );
};


export default OrderItem;